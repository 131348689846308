
import * as ipxRuntime$bLXOQnc2jF from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 360,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1920,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "s3.dev.tastyteam.cc",
    "s3.tastyteam.cc",
    "tastydrop.me",
    "tastystrike.gg"
  ],
  "alias": {
    "/static": "https://s3.tastyteam.cc/frontend/dota"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ],
  "quality": 70
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$bLXOQnc2jF, defaults: {"maxAge":2592000,"cacheDir":"/app/.cache/ipx"} }
}
        