import { EHeaderMenuItems, type IAppsConfig } from '~/config/apps/index.types';

export const useProjectSettings = () =>
  <IAppsConfig>{
    project: 'dota',
    game: 'dota',
    theme: 'purple',
    defaultCurrency: 'RUB',
    siteCurrency: 'RUB',
    auth: {
      isCheckboxesSelectedByDefault: true,
      variants: ['steam', 'google', 'vk'],
    },
    cases: {
      showButtons: true,
      priceProject: 'TASTYDROP',
      siteName: 'TastyDrop',
    },
    contacts: {
      adMail: 'media_td@tastyteam.cc',
      supportMail: 'support@tastyteam.cc',
      partnerMail: 'partner@tastyteam.cc',
      hideVk: false,
    },
    header: {
      bottomMenu: {
        left: ['home', 'battles'],
        right: ['daily', 'menu'],
      },
      sideMenu: [
        'daily',
        'secret',
        'bonuses',
        'contracts',
        'upgrades',
        'techies',
        'battles',
        'faq',
        'vk',
        'telegram',
        'instagram',
      ],
      desktopMenu: ['battles', 'techies', 'upgrades', 'contracts'],
      customLinks: {
        vk: 'https://vk.com/tastydrop',
        telegram: 'https://t.me/tastydropz',
        instagram: 'https://www.instagram.com/tastydrop_co/',
      },
      otherGamesLinks: {
        cs: 'https://click.open-case-welcome.com/6z3d4qUm?sub_id1=crosssaleTD&sub_id2=header&sub_id3=td&sub_id4=td&sub_id5=FRIENDTG',
      },
    },
    daily: {
      hideFromTop: false,
      hideTimer: false,
    },
    drop: {
      hideShare: false,
    },
    faq: {
      type3: {
        categoryId: 5,
        questionId: 19,
      },
    },
    footer: {
      copyright: 'TastyDROP',
      paymentMethods: ['visa', 'mastercard', 'qiwi', 'yoomoney', 'webmoney', 'hotskins'],
      hideSocials: false,
    },
    legalData: {
      beneficiary: 'OmegaWalls OÜ',
      registrationNumber: 16473723,
      address: 'Vesivärava tn 50-201 Tallinn Harjumaa 10152',
    },
    isBattlesDisabled: false,
    isChallengesDisabled: false,
    isProvablyFairDisabled: true,
    isUpgradesDisabled: false,
    isTechiesDisabled: false,
    hideBattlesBanner: false,
    hideSecretShop: false,
    hideFreeCasesBanner: false,
    hideHeaderMenuItems: [EHeaderMenuItems.LEADERBOARD, EHeaderMenuItems.PF],
    showPacksBanner: true,
    hideProjectSwitcher: false,
    howItWorks: {
      hideStep6: false,
    },
    supportKey: 'sePmTTSmEQ',
    gtmKey: 'GTM-WPQRW3B',
    seo: {
      favicon: '/img/favicon/dota.ico',
      title: 'Tastydrop - Кейсы Dota 2',
      description:
        'TastyDrop — это самый крупный сайт с кейсами по Дота 2. Покупай кейсы, получай арканы и имморталки! Вывод на Steam в течение 1 минуты.',
    },
    payment: {
      project: 'Tastydrop',
    },
    statistics: {
      hideElements: [],
    },
    techWork: {
      logoClass: 'logo--dota',
    },
    redirects: {
      newCases: '/new-cases',
    },
    profile: {
      hideSteamLogin: false,
    },
    subscriptions: {
      variants: ['email', 'steam', 'telegram', 'vk'],
      show: true,
    },
    upgrades: {
      priceMaxDifference: 8000,
      progressVars: {
        '--center': '#9E00FF',
        '--stroke': '#7745D0',
        'rgb': '0 0 0 0 0.498103 0 0 0 0 0.0667188 0 0 0 0 0.7625 0 0 0 1 0',
      },
    },
  };
